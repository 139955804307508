import { uuid } from "vue-uuid";

const initialState = () => ({
  index: [],
  indexLoading: "empty",
  layout: [],
  layoutLoading: "empty",
  showModal: false,
  showModalBuyContent: false,
  interval: false,
  selectedFoodMenu: [],
  waiting: [],
  purchasedContent: [],
  skyShop: [],
  isOrderProcessing: false,
  orderNumber: "",
  contentOrderNumber:""
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  ADD_ENTITY(state, [key, payload, position = "start"]) {
    if (position === "end") {
      state[key].push(payload);
    } else {
      state[key].unshift(payload);
    }
  },
  UPDATE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
      state[key].push(payload);
    }
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const actions = {
  async index({ commit, rootState, state, dispatch }) {
    if (state.interval) {
      clearInterval(state.interval);
    }
    if (rootState.user.authenticate) {
      //commit("SET_ENTITY", ["indexLoading", true]);
      let data = [];
      const response = await this._vm.axios.post("/api/board/userOrders", {
        nameHash: rootState.user.user.nameHash,
        seat: rootState.user.user.seat,
        user: localStorage.getItem("uuid")
      });
      data = response.data.filter(
        item =>
          item.state !== "canceledByPassenger" &&
          item.state !== "completed" &&
          item.orderCategory !== "buyContent" &&
          item.orderCategory !== "menuFood" &&
          item.orderCategory !== "skyShop"
      );
      commit("SET_ENTITY", ["index", data]);
      commit("SET_ENTITY", [
        "purchasedContent",
        response.data.filter(
          item =>
            item.orderCategory === "buyContent" &&
            item.state === "completed"
        )
      ]);
      commit("SET_ENTITY", [
        "selectedFoodMenu",
        response.data.filter(item => item.orderCategory === "menuFood")
      ]);
      commit("SET_ENTITY", [
        "skyShop",
        response.data.filter(item => item.orderCategory === "skyShop")
      ]);
      //   commit("SET_ENTITY", ["indexLoading", false]);
      if (data.length === 0) {
        if (state.interval) {
          clearInterval(state.interval);
        }
      } else {
        const interval = setInterval(async () => {
          dispatch("index");
        }, 15000);
        commit("SET_ENTITY", ["interval", interval]);
      }
    } else {
      const interval = setInterval(async () => {
        dispatch("index");
      }, 30000);
      dispatch("indexPurchasedContent");
      commit("SET_ENTITY", ["interval", interval]);
    }
  },
  async indexPurchasedContent({ commit, rootState }) {
    if (Object.values(rootState.flight.flight).length) {
      const response = await this._vm.axios.post("/api/board/userOrders", {
        user: localStorage.getItem("uuid")
      });
      commit("SET_ENTITY", [
        "purchasedContent",
        response.data.filter(item => item.orderCategory === "buyContent")
      ]);
    }
  },
  async layout({ commit, rootState }) {
    commit("SET_ENTITY", ["layoutLoading", true]);
    let data = {};
    try {
      const response = await this._vm.axios.get(
        "/api/serviceOnBoard",
        rootState.user.user
      );
      data = response.data;
      commit("SET_ENTITY", ["layout", data]);

      if (data.filter(el => el.category === "upgradePlace").length) {
        commit("flight/SET_ENTITY", ["isUpgrade", true], { root: true });
      }
    } catch (e) {
      commit("SET_ENTITY", ["layout", []]);
    }

    commit("SET_ENTITY", ["layoutLoading", false]);
  },
  async showModal({ commit, rootState, dispatch }) {
    if (!rootState.user.authenticate && rootState.flight.isService) {
      commit("user/SET_ENTITY", ["showModal", true], { root: true });
      commit("SET_ENTITY", ["showModal", true]);
    } else {
      await dispatch("flight/services", {}, { root: true });
      if (rootState.flight.isService) {
        commit("SET_ENTITY", ["showModal", true]);
      }
    }
  },
  async showModalBuyContent({ commit }) {
    // if (!rootState.user || !rootState.user.authenticate) {
    //   commit("user/SET_ENTITY", ["showModal", true], { root: true });
    //   // commit("SET_ENTITY", ["showModalBuyContent", true]);
    // } else {
    //   commit("SET_ENTITY", ["showModalBuyContent", true]);
    // }
    commit("SET_ENTITY", ["showModalBuyContent", true]);
  },
  async setOrder({ commit, rootState, state, dispatch }, payload) {
    if (rootState.user.authenticate) {
      if (state.waiting.find(item => item.id === payload.id)) {
        return;
      }
      commit("ADD_ENTITY", ["waiting", payload]);
      let data = {};
      const response = await this._vm.axios.post("/api/board/order", {
        nameHash: rootState.user.user.nameHash,
        user: localStorage.getItem("uuid"),
        seat: rootState.user.user.seat,
        orderCategory: "service",
        items: [payload]
      });
      data = response.data;
      commit("ADD_ENTITY", ["index", data]);
      commit("DELETE_ENTITY", ["waiting", payload, "id"]);

      if (state.interval) {
        clearInterval(state.interval);
      }
      const interval = setInterval(async () => {
        dispatch("index");
      }, 15000);
      commit("SET_ENTITY", ["interval", interval]);
    }
  },
  async setOrderBuyContent({ commit, rootState }, payload) {
    let data = {};
    if (rootState.user.authenticate) {
      data = {
        nameHash: rootState.user.user.nameHash,
        user: localStorage.getItem("uuid"),
        seat: rootState.user.user.seat,
        orderCategory: "buyContent",
        payloadContent: payload,
        totalPrice: payload.price
      };
      if (payload.state) {
        data.state = payload.state;
      }
      const response = await this._vm.axios.post("/api/board/order", data);
      data = response.data;
      commit("ADD_ENTITY", ["purchasedContent", data]);
      return data;
    } else if (Object.values(rootState.flight.flight).length) {
      data = {
        nameHash: "unauthorized",
        user: localStorage.getItem("uuid"),
        seat: "0a",
        orderCategory: "buyContent",
        payloadContent: payload,
        totalPrice: payload.price
      };
      if (payload.state) {
        data.state = payload.state;
      }
      const response = await this._vm.axios.post("/api/board/order", data);
      data = response.data;
      commit("ADD_ENTITY", ["purchasedContent", data]);
      return data;
    } else {
      const data = {
        nameHash: "unauthorized",
        user: localStorage.getItem("uuid"),
        seat: "0a",
        guid: uuid.v1(),
        state: "pending",
        orderCategory: "buyContent",
        payloadContent: payload,
        totalPrice: payload.price
      };
      commit("ADD_ENTITY", ["purchasedContent", data]);
      return data;
    }
  },
  async setOrderFoodMenu({ commit, rootState }, payload) {
    if (rootState.user.authenticate) {
      let data = {};
      let obj = {};
      obj[payload.ration] = payload.selected[payload.ration];
      const response = await this._vm.axios.post("/api/board/order", {
        nameHash: rootState.user.user.nameHash,
        user: localStorage.getItem("uuid"),
        seat: rootState.user.user.seat,
        orderCategory: "menuFood",
        selectMenuFood: obj,
        items: payload.items
      });
      data = response.data;

      commit("ADD_ENTITY", ["selectedFoodMenu", data]);
      return data;
    }
  },
  async setOrderUpdatePlace({ commit, rootState }, payload) {
    if (rootState.user.authenticate) {
      let data = {};
      const response = await this._vm.axios.post("/api/board/order", {
        nameHash: rootState.user.user.nameHash,
        seat: rootState.user.user.seat,
        user: localStorage.getItem("uuid"),
        orderCategory: "upgradePlace",
        items: payload.ids,
        paymentType: payload.paymentType,
        upgradeSeat: {
          typeUpgrade: payload.typeUpgrade,
          upgradeSeat: payload.upgradeSeat,
          places: payload.places,
          price: payload.price,
          rfisc: payload.rfisc,
          description: payload.description
        }
      });
      data = response.data;
      commit("ADD_ENTITY", ["index", data]);
      return data;
    }
  },
  async setOrderSkyShop({ commit }, payload) {
    // if (rootState.user.authenticate) {
    let data = {};
    // const response = await this._vm.axios.post("/api/board/order", {
    //   nameHash: rootState.user.user.nameHash,
    //   seat: rootState.user.user.seat,
    //   user: localStorage.getItem("uuid"),
    //   orderCategory: "skyShop",
    //   paymentType: payload.paymentType,
    //   items: payload.items
    // });

    const response = await this._vm.axios.post(
      "/api/pos/order-create",
      {
        // nameHash: rootState.user.user.nameHash,
        seat: payload.seat,
        // user: localStorage.getItem("uuid"),
        // orderCategory: "skyShop",
        // paymentType: payload.paymentType,
        email: payload.email,
        items: payload.items
      },
      {
        headers: { Authorization: "3a156a2e-06f2-4512-81a5-4b43450305f4" }
      }
    );
    commit("SET_ENTITY", ["isOrderProcessing", false]);

    data = response.data;
    commit("SET_ENTITY", ["orderNumber", data.receipt]);
    // return data;
    // }
  },
  async cancelOrder({ commit, rootState }, payload) {
    if (rootState.user.authenticate) {
      if (
        rootState.order.index.find(item =>
          item.items.find(it => it.id === +payload.id)
        )
      ) {
        await this._vm.axios.put("/api/board/orders", {
          guid: rootState.order.index.find(item =>
            item.items.find(it => it.id === payload.id)
          ).guid,
          state: payload.state || "canceledByPassenger"
        });
        commit("DELETE_ENTITY", [
          "index",
          rootState.order.index.find(item =>
            item.items.find(it => it.id === payload.id)
          ),
          "guid"
        ]);
      }
    }
  },
  async completeOrderGuid({ commit, rootState }, payload) {
    if (rootState.user.authenticate) {
      if (
        rootState.order.index.find(item => item.guid === payload.guid) ||
        rootState.order.purchasedContent.find(
          item => item.guid === payload.guid
        )
      ) {
        await this._vm.axios.put("/api/board/orders", {
          guid: payload.guid,
          state: payload.state || "canceledByPassenger"
        });

        commit("flight/SET_ENTITY", ["planeLoading", "empty"], { root: true });
        commit("DELETE_ENTITY", ["index", payload, "guid"]);
      }
    }
  },
  async updateOrderState({ rootState }, payload) {
    if (rootState.user.authenticate) {
      if (
        rootState.order.index.find(item => item.guid === payload.guid) ||
        rootState.order.purchasedContent.find(
          item => item.guid === payload.guid
        ) ||
        rootState.order.skyShop.find(item => item.guid === payload.guid)
      ) {
        await this._vm.axios.put("/api/board/orders", {
          guid: payload.guid,
          state: payload.state
        });
      }
    }
  },
  async completeOrderGuidBuyContent({ commit, rootState }, payload) {
    const findItem = rootState.order.purchasedContent.find(
      item => item.guid === payload.guid && item.state !== "completed"
    );
    if (findItem) {
      if (Object.values(rootState.flight.flight).length) {
        await this._vm.axios.put("/api/board/orders", {
          guid: payload.guid,
          state: payload.state || "canceledByPassenger"
        });
      }
      commit("UPDATE_ENTITY", [
        "purchasedContent",
        { ...findItem, ...payload },
        "guid"
      ]);
    }
    return findItem;
  },
  async errorOrderGuidBuyContent({ commit, rootState }, payload) {
    const findItem = rootState.order.purchasedContent.find(
      item => item.guid === payload.guid && item.state !== "completed"
    );
    if (findItem) {
      if (Object.values(rootState.flight.flight).length) {
        await this._vm.axios.put("/api/board/orders", {
          guid: payload.guid,
          state: payload.state || "canceledByPassenger"
        });
      }
      commit("DELETE_ENTITY", [
        "purchasedContent",
        { ...findItem, ...payload },
        "guid"
      ]);
    }
    return findItem;
  },
  async updateOrderAfterLogin({ commit, rootState, dispatch }) {
    if (rootState.user.authenticate) {
      const findItems = rootState.order.purchasedContent.filter(
        item => item.seat === "0a" && item.nameHash === "unauthorized"
      );
      if (findItems.length) {
        for (let i = 0; i < findItems.length; i++) {
          let payload = {
            guid: findItems[i].guid,
            state: "canceledByPassenger"
          };
          await this._vm.axios.put("/api/board/orders", payload);
          commit("DELETE_ENTITY", ["purchasedContent", findItems[i], "guid"]);
          dispatch("setOrderBuyContent", {
            ...findItems[i].payloadContent,
            price: findItems[i].totalPrice,
            state: findItems[i].state
          });
        }
      }
    }
  },
  async setOrderAfterSetFlight({ commit, rootState, dispatch }) {
    if (
      Object.values(rootState.flight.flight).length &&
      !rootState.user.authenticate
    ) {
      const findItems = rootState.order.purchasedContent.filter(
        item => item.seat === "0a" && item.nameHash === "unauthorized"
      );
      if (findItems.length) {
        for (let i = 0; i < findItems.length; i++) {
          commit("DELETE_ENTITY", ["purchasedContent", findItems[i], "guid"]);
          dispatch("setOrderBuyContent", {
            ...findItems[i].payloadContent,
            price: findItems[i].totalPrice,
            state: findItems[i].state
          });
        }
      }
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
