import Vue from "vue";
import VueI18n from "vue-i18n";

const languages = ["en", "ru"];
import Cookie from "vue-cookie";
Vue.use(VueI18n);

export default new VueI18n({
  // eslint-disable-next-line no-undef
  locale: Cookie.get("locale") || process.env.VUE_APP_I18N_LOCALE,
  // eslint-disable-next-line no-undef
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
  messages: load([
    "album",
    "captive",
    "drm-activation",
    "errors",
    "favorites",
    "feedback",
    "flight-info",
    "general",
    "languages",
    "movie",
    "navigation",
    "not-found",
    "orders",
    "pages",
    "section-menu",
    "video-info",
    "video-player-controls",
    "serials",
    "video-player-cover",
    "user",
    "crew",
    "player",
    "settings",
    "services",
    "food-menu",
    "planes",
    "codes",
    "upgrade-seat",
    "airline",
    "tenYears",
    "public",
    "shop",
    "help"
  ])
});

function load(names) {
  return languages.reduce(
    (languageResources, language) => ({
      ...languageResources,
      [language]: names.reduce(
        (resources, name) => ({
          ...resources,
          [name]: require(`@/locales/${name}/${language}.json`)
        }),
        {}
      )
    }),
    {}
  );
}
